import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { removeToken, sessionTimeoutCheck } from "../utils/RemoveToken";
import { API_BASE_URL } from "../utils/constants";
import { getToken } from "../utils/GlobalFunction";


const initialState = {
    userList : null,
    loading: false,
    message: null,
    error: null,
    singleUserList: null,
    searchVal : null
}


export const loadUserList = createAsyncThunk("UserInvitation/UserList", async()=>{

  const token  = getToken()
    
   const response =  await axios({ 
      withCredentials: true,
      method: "get",
      url: `${API_BASE_URL}/User_invitation/index`,
      headers:{'Accesstoken':token}
      });

    const data = (await response).data; 

    removeToken(data.status_code); 
    sessionTimeoutCheck(response.data);
    return data;  

})

export const updateUserStatus = createAsyncThunk("UserInvitation/UserStatus", async(mydata)=>{
  const token  = getToken()

  
 const response = await axios({
          withCredentials: true,
          method: "post",
          url: `${API_BASE_URL}/User_invitation/deactivateUser/${mydata.id}`,
          headers: {"Accesstoken": token,"Content-Type": "multipart/form-data" },
          data: {
            activation_type: mydata.val
          }
        })

        const data = (await response).data;  
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data);
        return data;  
});

//pendinf
export const addInviteUser = createAsyncThunk("UserInvitation/InviteUser", async(mydata)=>{
  const token  = getToken()

 const response = await axios({
          withCredentials: true,
          method: "post",
          url: `${API_BASE_URL}/User_invitation/doInviteUser`,
          headers: {"Accesstoken": token,"Content-Type": "multipart/form-data" },
          data: mydata
        })
       
        const data = (await response).data; 
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data);
        return data;
});

 
//update userInvitation
//pending
export const StoreUpdateInviteUser = createAsyncThunk("UserInvitation/UpdateInviteUser", async(mydata)=>{
  const token  = getToken()

 const response = await axios({
          withCredentials: true,
          method: "post",
          url: `${API_BASE_URL}/User_invitation/doUpdateInvitedUser/${mydata.id}`,
          headers: {"Accesstoken": token,"Content-Type": "multipart/form-data" },
          data: mydata
        });
       
        const data = (await response).data; 
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data);
        return data;
});


//get single user invitation data
export const getSingleUserInvData = createAsyncThunk("UserInvitation/GetSingleUserInvData", async(mydata)=>{
  const token  = getToken()

 const response = await axios({
          withCredentials: true,
          method: "post",
          url: `${API_BASE_URL}/User_invitation/getInvitedUserById/${mydata}`,
          headers: {"Accesstoken": token,"Content-Type": "multipart/form-data" },
          data: mydata
        });
       
        const data = (await response).data; 
        removeToken(data.status_code);
        sessionTimeoutCheck(response.data);
        return data;
});


const UserInvitationSlice = createSlice({
    name: "user_invitation",
    initialState,
    reducers: {
       LOAD_DATA:(state,action) =>{
        //  state.userList = action.payload.data  
         state.loading = false
         state.userList = action.payload.data
      },
      LOAD_DONE: (state,action)=>{
        state.loading = false
      },
      CHANGE_USER_STATUS : (state,action) =>{
        const userIndex = state.userList.findIndex(ele => ele.id === action.payload.id);
        const tempUser = [...state.userList];
        tempUser[userIndex].status = action.payload.val
        
      },
      CLEAR_SINGLE_USER_INVITATION:(state,action) =>{
      state.singleUserList = null
      },

      SET_SEARCH: (state,action) =>{
        state.searchVal = action.payload.searchs
      },
      CLEAR_USER_INVITATION_ADD:(state,action)=>{
        state.error = null
      }
    },
    extraReducers: (builder)=>{
      builder.addCase(loadUserList.pending, (state) => {
        state.loading = true
        state.userList = null
      })
      builder.addCase(loadUserList.fulfilled, (state, action) => {

        const ListWithId = action.payload?.data?.map((row,index)=>({
          cid: index+1,
          ...row
        }))

        state.loading = false
        state.userList = ListWithId
      })
      builder.addCase(loadUserList.rejected, (state, action) => {
        state.loading = false
        state.userList = null
      })

      //update User Status
      builder.addCase(updateUserStatus.pending, (state) => {
        // state.loading = true
        // state.userList = null
        // state.message = null 
      })
      builder.addCase(updateUserStatus.fulfilled, (state, action) => {
        const ListWithId = action.payload?.data.map((row,index)=>({
          cid: index+1,
          ...row
        }))
        // state.loading = false
        state.userList = ListWithId
        // state.message = action.payload.message
        
      })
      builder.addCase(updateUserStatus.rejected, (state, action) => {
        // state.loading = false
        // state.userList = null
        // state.message = null
      });


      //Invite Role
      builder.addCase(addInviteUser.pending, (state) => {
        // state.loading = true
        state.message = null 
      })
      builder.addCase(addInviteUser.fulfilled, (state, action) => {
        // state.loading = false
        state.message = action.payload.message
        state.error = action.payload.errors
      })
      builder.addCase(addInviteUser.rejected, (state, action) => {
        // state.loading = false
        state.message = null
        state.error = null
      });

      //update Invite Role
      builder.addCase(StoreUpdateInviteUser.pending, (state,action) => {
        // state.loading = true
        state.message = null 
        state.error = null
      })
      builder.addCase(StoreUpdateInviteUser.fulfilled, (state, action) => {
        // state.loading = false
        state.message = action.payload.message
        state.error = action.payload.errors
      })
      builder.addCase(StoreUpdateInviteUser.rejected, (state, action) => {
        // state.loading = false
        state.message = null
        state.error = null
      });

      // //get single user invitation data
      builder.addCase(getSingleUserInvData.pending, (state,action) => {
        state.loading = true
        state.message = null 
        state.error = null
        state.singleUserList = null
      })
      builder.addCase(getSingleUserInvData.fulfilled, (state, action) => {
        state.loading = false
        state.message = action.payload.message
        state.error = action.payload.errors
        state.singleUserList = action.payload.data
      })
      builder.addCase(getSingleUserInvData.rejected, (state, action) => {
        state.loading = false
        state.message = null
        state.error = null
        state.singleUserList = null
      });

    }       
    
});


export const {LOAD_DATA,LOAD_DONE,CHANGE_USER_STATUS,SEARCH,SET_SEARCH,CLEAR_SINGLE_USER_INVITATION,
  CLEAR_USER_INVITATION_ADD
} = UserInvitationSlice.actions;

export const get_user_List = (state) => state.userInvitation.userList
export const get_user_List_loading = (state) => state.userInvitation.loading
export const userInvitation_Message = (state) => state.userInvitation.message
export const userInvitation_Error = (state) => state.userInvitation.error
export const get_Single_User = (state) => state.userInvitation.singleUserList
export const serchValue = (state) => state.userInvitation.searchVal

export default UserInvitationSlice.reducer;

