import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken } from "../utils/GlobalFunction";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken } from "../utils/RemoveToken";

const initialState = {
    notifications : null,
    error: null
}
  
  export const updateNotificationStatus = createAsyncThunk("notification/updateNotificationStatus", async(myData)=>{
   
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/enableDisableTaskNotification/${myData?.task_id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  myData
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  export const listNotification = createAsyncThunk("notification/list", async(myData)=>{
   
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "get",
        url: `${API_BASE_URL}/Projects/notificationList`,
        headers:{'Accesstoken':token},
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  export const singleNotification = createAsyncThunk("notification/singleNotification", async(myData)=>{
   
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "get",
        url: `${API_BASE_URL}/Projects/notificationById/${myData.id}`,
        headers:{'Accesstoken':token},
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  export const updateNotification = createAsyncThunk("notification/update", async(myData)=>{
   
    const token  = getToken()
    // console.log({...myData.userListData,status: myData.status,template: myData.template});
    
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Projects/updateNotificationStatus/${myData.id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  {...myData.userListData,status: myData.status,template: myData.template}
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  export const updateMissedTimesheetNotification = createAsyncThunk("notification/updateMissedTimesheetNotification", async(myData)=>{
    // console.log(myData.data);
    
    const token  = getToken()
    // console.log({...myData?.userListData,...myData?.userListExclude,status: myData?.status,template: myData?.template,
    //   less_than_hours:myData?.less_than_hours, difference: myData?.difference});
    
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Projects/updateNotificationStatus/${myData.id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  {...myData.userListData,...myData.userListExclude,status: myData.status,template: myData.template,
          less_than_hours:myData?.less_than_hours, difference: myData?.difference
        }
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });


const NotificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
    
    },
    
});


export const {} = NotificationsSlice.actions;

export default NotificationsSlice.reducer;

