import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "../utils/GlobalFunction";
import { API_BASE_URL } from "../utils/constants";
import { removeToken, sessionTimeoutCheck } from "../utils/RemoveToken";

const initialState = {
    loading : null,
    requestTimelogSubmitList : null
}



export const getSubmitedTimeLogList = createAsyncThunk("timeapproval/requestList",async()=>{


    axios.defaults.withCredentials = true;
    const token = getToken();
    const response = await axios({
     withCredentials: true,
     method: "get",
     url: `${API_BASE_URL}/Timelog/timelogDateRequest`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },

    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)

    return data;
    
});



export const addTimeLogRequest = createAsyncThunk("timeapproval/addRequest",async(myData)=>{


    axios.defaults.withCredentials = true;
    const token = getToken();
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Timelog/addTimelogDateRequest`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
    data: {start_date: myData.start_date ,end_date: myData.end_date, user_comments:  myData.user_comments}    
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)

    return data;
    
});



const TimeApprovalSlice = createSlice({
    name: "timeapproval",
    initialState,
    reducers: {
        
    ActiveModal: (state,action) =>{
    
        state.curruntModal = {type: action.payload.type, 
            status: action.payload.status, 
            previousModal: action.payload.previousModal,
            taskId: action.payload.taskId    
        }

    },
    DeactivateModal: (state,action) => {
        state.curruntModal = {type: action.payload.type, status: action.payload.status}
    }
    
    }
    
});




export const getSubmitedRequestList = createAsyncThunk("timeapproval/approval-request-list",async()=>{


    axios.defaults.withCredentials = true;
    const token = getToken();
    const response = await axios({
     withCredentials: true,
     method: "get",
     url: `${API_BASE_URL}/Timelog/approveRequest`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },

    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)

    return data;
    
});


export const doRejectRequest = createAsyncThunk("timeapproval/doRejectRequest",async(myData)=>{

    console.log(myData);
    axios.defaults.withCredentials = true;
    const token = getToken();
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Timelog/doApproveRequest`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {id: myData.id, request_status:'2', hr_comments: myData.hr_comments}   
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)

    return data;
    
});

export const doAcceptRequest = createAsyncThunk("timeapproval/doAcceptRequest",async(myData)=>{

    console.log(myData);
    axios.defaults.withCredentials = true;
    const token = getToken();
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Timelog/doApproveRequest`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {id: myData.id, request_status:'1'}   
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)

    return data;
    
});





export const {ActiveModal,DeactivateModal} = TimeApprovalSlice.actions;

export default TimeApprovalSlice.reducer;

export const getSubmittedTimeApprovalList = (state) => state.timeapproval.requestTimelogSubmitList;