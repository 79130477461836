import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken, sessionTimeoutCheck } from "../utils/RemoveToken";
import { getToken } from "../utils/GlobalFunction";

const initialState = {
    loading : null,
    timelogList : null,
    sub_task_id : null,
    task_id: null,
    project_bill_type : null,
    lastUpdate: null,
    logTask: null,
    finalDates: null
}


export const timeLogChecklist = createAsyncThunk("TimeLog/list",async(myData)=>{


    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/insertTimelogModal/${myData.tid}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {type: myData.type},
    });
 
    // toast.error(response.data.msg)
  
    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data);
    return data;
    
});

export const removeTimelog = createAsyncThunk("TimeLog/removeTimelog",async(myData)=>{


    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/removeTimelog/${myData.logId}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)
    return data;
    
});

export const addTimelog = createAsyncThunk("TimeLog/addTimelog",async(myData)=>{
   
    const timelogdata = {
        task_type: myData.type,
        main_task_id:  myData.cid,
        complete_date:  myData.saveDate,
        bill_type:   myData.data.billType,
        timelog:    myData.data.hours,
        notes: myData.data.notes,
    }

    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/doInsertTimelog/${myData.stid}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: timelogdata
    });

    const data = (await response).data;
    return data;
   
    
});

export const updateTimelog = createAsyncThunk("TimeLog/updateTimelog",async(myData)=>{

    
    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/updateInsertedTimelog/${myData.logId}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: myData.data
    });
   
    const data = (await response).data;
    return data;
   
    
});

export const updateCompletedDate = createAsyncThunk("TimeLog/updateCompletedDate",async(myData)=>{


    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/updateInsertedTimelog/${myData.logId}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {complete_date: myData.completed_date}
    });

    const data = (await response).data;
     return data;

});   




export const updateBillableType = createAsyncThunk("TimeLog/updateBillableType", async(myData)=>{

    const token  = localStorage.getItem('access_token');
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/updateTaskBillType/${myData.id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: {
            bill_type: myData.bill_type
        }
      });
        
      const data = (await response).data; 
     
      removeToken(data.date); 
      return data;  
  
  })

  export const updateCompleteDate = createAsyncThunk("TimeLog/updateCompleteDate", async(myData)=>{

    const token  = localStorage.getItem('access_token');
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/updateCompleteDate/${myData.id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: {
            complete_date: myData.date
        }
      });
        
      const data = (await response).data; 
   
      removeToken(data.date); 
      return data;  
  
  });

  export const updateTimeSheetTimeLog = createAsyncThunk("TimeLog/updateTimeSheetTimeLog", async(myData)=>{

    const token  = localStorage.getItem('access_token');
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/updateTimelogHours/${myData.id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: {
            hours: myData.timelog
        }
      });
        
      const data = (await response).data; 
   
      removeToken(data.date); 
      return data;  
  
  })

  export const updateTimesheetNotes = createAsyncThunk("TimeLog/updateTimesheetNotes", async(myData)=>{

    const token  = localStorage.getItem('access_token');
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/updateTimelogNotes/${myData.id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: {
            notes: myData.notes
        }
      });
        
      const data = (await response).data; 
    
      removeToken(data.date); 
      return data;  
  
  })



  export const userWiseProject = createAsyncThunk("TimeLog/userWiseProject", async(myData)=>{

    const token  = localStorage.getItem('access_token');
  
     const response =  await axios({ 
        withCredentials: true,
        method: "get",
        url: `${API_BASE_URL}/Timelog/userProjectReport`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
      });
        
      const data = (await response).data; 
    
      removeToken(data.date); 
      return data;  
  
  })


  export const userWiseProjectWrapper = createAsyncThunk("TimeLog/userWiseProjectWrapper", async(myData)=>{
  
    console.log(myData.eventuallyReportsTo);
    
    const token  = localStorage.getItem('access_token');
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/userProjectReportWrapper`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: {
            date: myData.date,
            ...myData.reportTo,
            ...myData.eventuallyReportsTo
        },
      });
        
      const data = (await response).data; 
      
      removeToken(data.date); 
      return data;  
  
  })


const timeLogSlice = createSlice({
    name: "timeLog",
    initialState,
    reducers: {
    SET_SUB_TASK_ID : (state,action) =>{
            state.sub_task_id = action.payload.stid
        },
    SET_TASK_ID :(state,action) =>{
            state.task_id = action.payload.tid
        },
    CLEAR_TASK_ID:(state,action) =>{
            state.task_id = null
        },
    REMOVE_TIME_LOG: (state,action) =>{
       
       const timelogIndex = state.timelogList.findIndex(ele => ele.id === action.payload.logId);
       state.timelogList.splice(timelogIndex,1)

    },
    UPDATE_LOG_TIME : (state,action) =>{
        const logIndex = state.timelogList.findIndex(ele => ele.id === action.payload.logId);
        if(action.payload.name === "billType"){
            state.timelogList[logIndex].bill_type = action.payload.value;
            
        }else if(action.payload.name === "date"){
            state.timelogList[logIndex].complete_date = action.payload.value;
           
        }else if(action.payload.name === "hours"){
            state.timelogList[logIndex].timelog = action.payload.value;
         
        }else if(action.payload.name === "notes"){
            state.timelogList[logIndex].notes = action.payload.value;
        
        }else{
            
        }
        


       
    },
    LAST_UPDATE:(state,action)=>{
        state.lastUpdate = Date.now()
    } 
    },extraReducers:(builder)=>{
        builder
        //timelog list
        .addCase(timeLogChecklist.pending,(state,action)=>{
            state.loading = true
            state.timelogList = null
            state.finalDates = null
            state.project_bill_type = null
            state.logTask = null
        })
        .addCase(timeLogChecklist.fulfilled,(state,action)=>{
            state.loading = false
            state.timelogList = action.payload.data?.timelog
            state.project_bill_type = action.payload.data?.project_bill_type
            state.finalDates = action.payload.data?.final_dates
            state.logTask = action.payload.data?.tasks
           
        })
        .addCase(timeLogChecklist.rejected,(state,action)=>{
            state.loading = false
            state.timelogList = null
            state.project_bill_type = null
            state.finalDates = null
        })
    


    }     
    
});


export const {REMOVE_TIME_LOG,UPDATE_LOG_TIME,SET_SUB_TASK_ID,SET_TASK_ID,LAST_UPDATE,CLEAR_TASK_ID} = timeLogSlice.actions;

export default timeLogSlice.reducer;


export const get_timelog_list = (state) => state.timeLog.timelogList;
export const subtaskID = (state) => state.timeLog.sub_task_id;
export const taskID = (state) => state.timeLog.task_id;

export const project_billtype = (state) => state.timeLog.project_bill_type
export const lastChange = (state) => state.timeLog.lastUpdate
export const TimeLogTask = (state) => state.timeLog.logTask
export const getFinalDate = (state) => state.timeLog.finalDates
