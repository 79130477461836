import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken, sessionTimeoutCheck } from "../utils/RemoveToken";
import { getToken } from "../utils/GlobalFunction";
const initialState = {
    loading : null,
    subTask : null
}


export const loadSubtask = createAsyncThunk("SubTask/list",async(myData)=>{


    axios.defaults.withCredentials = true;
    const token = getToken();
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Projects/subtaskModal/${myData.stid}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {type:myData.type},
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)
    return data;
    
});

export const updateSubtaskStatus = createAsyncThunk("SubTask/updateSubtaskStatus",async(myData)=>{
    
    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/updateTaskStatus/${myData.stid}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {'type': 'sub-task', status: myData.value},
    });

    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)
    return data;
    
});

export const updateSubtaskPriority = createAsyncThunk("SubTask/updateSubtaskPriority",async(myData)=>{

    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/updateTaskPriority/${myData.stid}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {'type': 'sub-task', priority: myData.value},
    });
   
    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)
    return data;
    
});

export const updateSubtaskStartDueDate = createAsyncThunk("SubTask/updateSubtaskStartDueDate",async(myData)=>{

    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/updateStartDueDate/${myData.stid}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {'type': 'sub-task', start_date: myData.start_date,due_date: myData.due_date},
    });
  
    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)
    return data;
    
});

export const updateSubtaskNotes = createAsyncThunk("SubTask/updateSubtaskNotes",async(myData)=>{


    axios.defaults.withCredentials = true;
    const token = getToken()
    const response = await axios({
     withCredentials: true,
     method: "post",
     url: `${API_BASE_URL}/Task/doUpdateNotes/${myData.stid}`,
     headers: { "Content-Type": "multipart/form-data", Accesstoken: token  },
     data: {'task_type': 'sub-task', notes: myData.value},
    });
   
    const data = (await response).data;
    removeToken(data.status_code);
    sessionTimeoutCheck(response.data)
    return data;
    
});




const subTaskSlice = createSlice({
    name: "subTasks",
    initialState,
    reducers: {
        
    UPDATE_SUB_TASK: (state,action) =>{

        if(action.payload.name === "status"){
            state.subTask.task_status = action.payload.value;
            
        }else if(action.payload.name === "priority"){
            state.subTask.priority = action.payload.value;
           
        }else if(action.payload.name === "state_date"){
            state.subTask.start_date = action.payload.value;
         
        }else if(action.payload.name === "due_date"){
            state.subTask.due_date = action.payload.value;
        
        }else{
            
        }
        
        if(action.payload.name === "notes"){
           state.subTask.notes = action.payload.value;
        }
       

    },
    ASSIGN_SUBTASK_USER: (state,action) =>{
        if(state.subTask.owner !==  action.payload.uid && state.subTask.user_name !== action.payload.name){
            state.subTask.owner =  action.payload.uid
            state.subTask.user_name  = action.payload.name
        }

    }, 
    },extraReducers:(builder)=>{
        builder
        //timelog list
        .addCase(loadSubtask.pending,(state,action)=>{
            state.loading = true
            state.subTask = null
        })
        .addCase(loadSubtask.fulfilled,(state,action)=>{
            state.loading = false
            state.subTask = action.payload.data.sub_tasks
           
        })
        .addCase(loadSubtask.rejected,(state,action)=>{
            state.loading = false
            state.subTask = null
        })
    


    }     
    
});


export const {UPDATE_SUB_TASK,ASSIGN_SUBTASK_USER} = subTaskSlice.actions;

export default subTaskSlice.reducer;


export const get_Single_Subtask = (state) => state.subTasks.subTask;

