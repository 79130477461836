import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken } from "../utils/GlobalFunction";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken } from "../utils/RemoveToken";

const initialState = {
    comments : null,
    uploadProgress: {},
    status: 'idle',
    error: null
}


export const commentsListByTask = createAsyncThunk("comments/CommentList", async(taskid)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/commentsList`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  {task_id:taskid}
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  export const addComments = createAsyncThunk("comments/addComment", async(myData)=>{
    
    console.log(myData);
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/addTaskComments`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  myData
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  export const deleteCommentsByid = createAsyncThunk("comments/deleteComment", async(myData)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/deleteComments`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  {comment_id:myData}
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });


  export const updateCommentsByid = createAsyncThunk("comments/updateComment", async(myData)=>{
  
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/updateTaskComments`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  myData
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

  
  export const enableNotification = createAsyncThunk("comments/notification", async(myData)=>{
   
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/enableDisableTaskNotification/${myData?.task_id}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  myData
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });


  



  export const uploadFiles  = createAsyncThunk("Attechment/uploadFiles", async(myData)=>{

    // const formData = new FormData();
    // for (let i = 0; i < files.length; i++) {
    //   formData.append('files', files[i]);
    // }


    const token  = getToken()

    

 
        

   
  });





const CommentsSlice = createSlice({
    name: "comments",
    initialState,
    reducers: {
    
    },
    
});


export const {} = CommentsSlice.actions;

export default CommentsSlice.reducer;

export const getModalState = (state) => state.comments.curruntModal;