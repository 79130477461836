import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken } from "../utils/RemoveToken";
import { getToken } from "../utils/GlobalFunction";


const initialState = {
    Productivity : null,
    timelog : null,
    searchTimelog: null,
    myTeamtimelog : null,
    myTeamsearchTimelog: null,
    loading : null
}

export const loadMyPerformance = createAsyncThunk("myPerformace/loadPerformanceData", async(myData)=>{

    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/totalLoggedTimeWrapper`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  {performance_type:myData.performanceType}
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  })
  export const serachMyPerformance = createAsyncThunk("myPerformace/serachPerformanceData", async(myData)=>{

    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Timelog/searchUserTimelog`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data:  {performance_type: myData.performanceType,date:myData.newDate,...myData.myLeaveAndHolidaysObj,bill_non_bill_filter:myData.BillType}
        
      });
        
      const data = (await response).data; 
      removeToken(data.data); 
      return data;  
  
  })


  
export const loadMyTeamPerformance = createAsyncThunk("myPerformace/loadMyTeamPerformance", async(myData)=>{

  const token  = getToken()

   const response =  await axios({ 
      withCredentials: true,
      method: "post",
      url: `${API_BASE_URL}/Timelog/totalLoggedTimeWrapper`,
      headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
      data:  {performance_type:myData.performanceType}
      
    });
      
    const data = (await response).data; 

    removeToken(data.data); 
    return data;  

})
export const serachMyTeamPerformance = createAsyncThunk("myPerformace/serachMyTeamPerformance", async(myData)=>{

  const token  = getToken()

   const response =  await axios({ 
      withCredentials: true,
      method: "post",
      url: `${API_BASE_URL}/Timelog/searchUserTimelog`,
      headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
      data:  {performance_type: myData.performanceType,date:myData.newDate,...myData.myLeaveAndHolidaysObj,
        bill_non_bill_filter:myData.BillType, ...myData.reportToFilter, ...myData.eventullyReportsFilter}
      
    });
      
    const data = (await response).data; 
    removeToken(data.data); 
    return data;  

})

  // export const serachTeamPerformance = createAsyncThunk("myPerformace/serachPerformanceData", async(myData)=>{

  //   const token  = getToken()
  
  //    const response =  await axios({ 
  //       withCredentials: true,
  //       method: "post",
  //       url: `${API_BASE_URL}/Timelog/searchUserTimelog`,
  //       headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
  //       data:  {performance_type: myData.performanceType,date:myData.newDate,...myData.myLeaveAndHolidaysObj,bill_non_bill_filter:myData.BillType}
        
  //     });
        
  //     const data = (await response).data; 
  //     removeToken(data.data); 
  //     return data;  
  
  // })

const MyPerformanceSlice = createSlice({
    name: "myPerformace",
    initialState,
    reducers: {
       LOAD_DATA:(state,action) =>{
        //  state.userList = action.payload.data  
         state.loading = false
         state.userList = action.payload.data
      },
      CLEAR_PROGRESS_BAR:(state, action) =>{
        state.Productivity = null
        state.timelog = null
        state.searchTimelog= null
        state.myTeamtimelog = null
        state.myTeamsearchTimelog= null
        state.loading = null
      }
    },
    extraReducers: (builder)=>{
            
          builder.addCase(loadMyPerformance.pending, (state,action) => {
            state.loading = true
            state.timelog = null
            state.Productivity = null
              })
            builder.addCase(loadMyPerformance.fulfilled, (state, action) => {
            state.loading = false
            state.timelog = action.payload.data.timelog
            state.Productivity = action.payload.data.timelog
             })
            builder.addCase(loadMyPerformance.rejected, (state, action) => {
            state.loading = false
            state.timelog = null
            state.Productivity = null
             })  


             builder.addCase(serachMyPerformance.pending, (state,action) => {
             state.loading = true
             state.searchTimelog = null
             })
             builder.addCase(serachMyPerformance.fulfilled, (state, action) => {
             state.loading = false
             state.timelog = null
             state.searchTimelog = action.payload.data.timelog
             })
             builder.addCase(serachMyPerformance.rejected, (state, action) => {
             state.loading = false
             state.searchTimelog = null
             })  

             
             builder.addCase(loadMyTeamPerformance.pending, (state,action) => {
              state.loading = true
              state.myTeamtimelog = null

                })
              builder.addCase(loadMyTeamPerformance.fulfilled, (state, action) => {
              state.loading = false
              state.myTeamtimelog = action.payload.data.team_report

               })
              builder.addCase(loadMyTeamPerformance.rejected, (state, action) => {
              state.loading = false
              state.myTeamtimelog = null

               })  
  
  
               builder.addCase(serachMyTeamPerformance.pending, (state,action) => {
               state.loading = true
               state.myTeamsearchTimelog = null
               })
               builder.addCase(serachMyTeamPerformance.fulfilled, (state, action) => {
               state.loading = false
               state.myTeamtimelog = null
               state.myTeamsearchTimelog = action.payload.data.team_report
               })
               builder.addCase(serachMyTeamPerformance.rejected, (state, action) => {
               state.loading = false
               state.myTeamsearchTimelog = null
               })  
             
    }

});




export const {LOAD_DATA,CLEAR_PROGRESS_BAR} = MyPerformanceSlice.actions;

export const myPrformancetimeLog = (state) => state.myPerformace.timelog
export const myPrformanceSearchtimeLog = (state) => state.myPerformace.searchTimelog
export const proDuctivity = (state) => state.myPerformace?.Productivity
export const myTeamSearchTimelog = (state) => state.myPerformace.myTeamsearchTimelog
export const myTeamTimelog = (state) => state.myPerformace.myTeamtimelog

export default MyPerformanceSlice.reducer