import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading : null,
    curruntModal : {type: "" , status: 0, previousModal: "", taskId : null}
}


const ModalSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        
    ActiveModal: (state,action) =>{
    
        state.curruntModal = {type: action.payload.type, 
            status: action.payload.status, 
            previousModal: action.payload.previousModal,
            taskId: action.payload.taskId    
        }

    },
    DeactivateModal: (state,action) => {
        state.curruntModal = {type: action.payload.type, status: action.payload.status}
    }
    
    }
    
});

export const {ActiveModal,DeactivateModal} = ModalSlice.actions;

export default ModalSlice.reducer;

export const getModalState = (state) => state.modals.curruntModal;