import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken } from "../utils/GlobalFunction";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken } from "../utils/RemoveToken";

const initialState = {
    organizationList : null,
    error: null
}


export const listOrganization = createAsyncThunk("comments/CommentList", async(taskid)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "get",
        url: `${API_BASE_URL}/Organization/index`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });







const OrganizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
    
    },
    
});


export const {} = OrganizationSlice.actions;

export default OrganizationSlice.reducer;

export const getOrganization = (state) => state.organization.organizationList;