
import { toast } from 'react-toastify';
export const removeToken = (status)=>{
    // console.log({status});
    if(status === 400){
        localStorage.removeItem('access_token');
        window.location.href = "/login";
    }
    
}
var count = 0
var count1 = 0
export const sessionTimeoutCheck = (data) =>{
   if(data.message === "User Logged Out!!"){
    if(count1 === 0){
        toast.error(data.message);
        count++;
    }
    
    
    setTimeout(()=>{
        localStorage.removeItem('access_token')
        window.location.href = "/login";
    },2000)
   
}    
//access denied
   if(data.status_code === 201){
    toast.error(data.message || data.msg);
    // if(count === 0){
    //    toast.error(data.message); 
    //    count++;
    // }
    
    // setTimeout(()=>{
    //     // getToken();
    //     window.location.href = "/";
    // },2000)
   
   }
}

