import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken, sessionTimeoutCheck } from "../utils/RemoveToken";
import { getToken } from "../utils/GlobalFunction";


const initialState = {
    timelog : null,
    loading : null,
    finalDates: null
}

export const loadtimelog = createAsyncThunk("myTimeSheet/loadtimelog", async()=>{

    const token  = getToken()

     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/myTimesheetWrapper`,
        headers:{'Accesstoken':token}
        });

      const data = (await response).data; 
      removeToken(data.status_code); 
      sessionTimeoutCheck(response.data);
      return data;  
  
  })

  export const searchtimelog = createAsyncThunk("myTimeSheet/searchtimelog", async(myData)=>{

    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/searchTimesheet`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: {
          date: myData.newDate,bill_type: myData.BillType, ...myData.myProjectObj,...myData.myUsersObj
        }
      });
        
      const data = (await response).data; 

      removeToken(data.date); 
      sessionTimeoutCheck(response.data);
      return data;  
  
  })

  export const exportsTimeSheet = createAsyncThunk("timesheet/exportTimeSheet", async(myData)=>{

    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Task/exportTimesheet`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        data: myData.formData
      });
        
      const data = (await response).data; 

      removeToken(data.date); 
      sessionTimeoutCheck(response.data);
      return data;  
  
  })


  const TimeSheetSlice = createSlice({
    name: "mytimesheet",
    initialState,
    reducers: {
       LOAD_DATA:(state,action) =>{
        //  state.userList = action.payload.data  
         state.loading = false
         state.userList = action.payload.data
      },
    },
    
    extraReducers: (builder)=>{
        
            builder.addCase(loadtimelog.pending, (state,action) => {
            state.loading = true
            state.timelog = null
              })
            builder.addCase(loadtimelog.fulfilled, (state, action) => {
            state.loading = false
            state.timelog = action.payload.data.timelog_data
            state.finalDates = action.payload.data.final_dates
             })
            builder.addCase(loadtimelog.rejected, (state, action) => {
            state.loading = false
            state.timelog = null
             })  
             
             builder.addCase(searchtimelog.pending, (state,action) => {
              state.loading = true
              state.timelog = null
              state.finalDates = null
                })
              builder.addCase(searchtimelog.fulfilled, (state, action) => {
              state.loading = false
              state.timelog = action.payload.data.timelog_data
              state.finalDates = action.payload.data.final_dates
               })
              builder.addCase(searchtimelog.rejected, (state, action) => {
              state.loading = false
              state.timelog = null
              state.finalDates = null
               })  

    }


    });




    export const {LOAD_DATA} = TimeSheetSlice.actions;

    export const mytimeLog = (state) => state.mytimesheet?.timelog
    export const final_dates = (state) => state.mytimesheet?.finalDates

    export default TimeSheetSlice.reducer;
