import { v4 as uuidv4 } from 'uuid';


const cardData = 
[
    {
        id:uuidv4(),
        title: "DWAO Internal",
        createDate: "",
        Status: "Active",
       
        projectTasks : [
            {
                id:uuidv4(),
                title: "Card 1",
                
                cards: [
                    {
                        id: uuidv4(),
                        title: 'Card1',
                        startDate: "",
                        endDate: "",
                        priority: "",
                        progress: "",
                        note:"",
                        tasks: [
                            {
                                id:uuidv4(),
                                title: 'task1',
                                date: Date.now(),
                                isDone:false,
                                timelog: [
                                    {
                                        id:uuidv4(),
                                        BillType: "Billable",
                                        date: "10-20-2023",
                                        hours: "1:00",
                                        noted: "hello",
                                    },
                                    {
                                        id:uuidv4(),
                                        BillType: "Billable",
                                        date: "10-20-2023",
                                        hours: "1:00",
                                        noted: "hello",
                                    }
                                ]
                            },
                            {
                                id:uuidv4(),
                                title: 'task2',
                                date: Date.now(),
                                isDone:false,
                                timelog: [
                                    {
                                        id:uuidv4(),
                                        BillType: "Billable",
                                        date: "10-20-2023",
                                        hours: "1:00",
                                        noted: "hello",
                                    }
                                ]
                            },
        
                        ],
                        color: "#3498db",
                        date: Date.now(),
                        isDone: false,
                        lable:[
                            
                            {
                                id: uuidv4(),
                                title: "p1",
                                badge: "primary",
                                check: false
                            },
                            {
                                id: uuidv4(),
                                title: "p2",
                                badge: "success",
                                check: false
                            },
                            {
                                id: uuidv4(),
                                title: "p3",
                                badge: "secondary",
                                check: false
                            },
                            {
                                id: uuidv4(),
                                title: "p4",
                                badge: "danger",
                                check: false
                            },
                            {
                                id: uuidv4(),
                                title: "p5",
                                badge: "dark",
                                check: false
                            },
                        ]
                    },
                    {
        
                        id: uuidv4(),
                        title: 'Card2',
                        startDate: "",
                        endDate: "",
                        priority: "",
                        progress: "",
                        note:"",
                        tasks: [
                            {
                                id:uuidv4(),
                                title: 'task3',
                                date: Date.now(),
                                isDone:false,
                                timelog: [
                                    {
                                        id:uuidv4(),
                                        BillType: "Billable",
                                        date: "10-20-2023",
                                        hours: "1:00",
                                        noted: "hello3",
                                    }
                                ]
                            },
                            {
                                id:uuidv4(),
                                title: 'task4',
                                date: Date.now(),
                                isDone:false,
                                timelog: [
                                    {
                                        id:uuidv4(),
                                        BillType: "Billable",
                                        date: "10-20-2023",
                                        hours: "1:00",
                                        noted: "hello4",
                                    }
                                ]
                            },
                        ],
                        lable:[
                            
                            {
                                id: uuidv4(),
                                title: "p1",
                                badge: "primary",
                                check: false
                            },
                            {
                                id: uuidv4(),
                                title: "p2",
                                badge: "success",
                                check: false
                            },
                            {
                                id: uuidv4(),
                                title: "p3",
                                badge: "secondary",
                                check: false
                            },
                            {
                                id: uuidv4(),
                                title: "p4",
                                badge: "danger",
                                check: false
                            },
                            {
                                id: uuidv4(),
                                title: "p5",
                                badge: "dark",
                                check: false
                            },
                        ],
                        color: "#9b59b6",
                        date: Date.now(),
                        isDone: false,
                    },
                    
                ],
                color:"#2c3e50",
                desc:"dtn le me down",
                isComplete: false
            },
           
        ]
    
    
    } 
]




export default cardData;