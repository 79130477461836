import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken } from "../utils/GlobalFunction";
import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import { removeToken } from "../utils/RemoveToken";

const initialState = {
    logList: null,
    error: null
}


export const getLogList = createAsyncThunk("logs/Loglist", async(itemCount)=>{
    
    const token  = getToken()
  
     const response =  await axios({ 
        withCredentials: true,
        method: "post",
        url: `${API_BASE_URL}/Internal/getLogsViewWrapper/${itemCount}`,
        headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
        // data:  {task_id:taskid}
        
      });
        
      const data = (await response).data; 
  
      removeToken(data.data); 
      return data;  
  
  });

//   export const addComments = createAsyncThunk("comments/addComment", async(myData)=>{
    
//     console.log(myData);
//     const token  = getToken()
  
//      const response =  await axios({ 
//         withCredentials: true,
//         method: "post",
//         url: `${API_BASE_URL}/Task/addTaskComments`,
//         headers:{'Accesstoken':token,"Content-Type": "multipart/form-data"},
//         data:  myData
        
//       });
        
//       const data = (await response).data; 
  
//       removeToken(data.data); 
//       return data;  
  
//   });





const LogsSlice = createSlice({
    name: "logs",
    initialState,
    reducers: {
    
    },
    
});


export const {} = LogsSlice.actions;

export default LogsSlice.reducer;

export const getLogs = (state) => state.logs.logList;