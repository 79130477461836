// import Cookies from 'js-cookie';

import { toast } from "react-toastify";

export const  timeConvert =(n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
  
    return `${rhours < 10 ? "0"+rhours : rhours === 0 ? "00" : rhours} : ${rminutes === 0 ? "00" : rminutes}`
}



export const LastUpdatedTime = (time) => {
 const now = new Date();
 const lastChange = new Date(time);
 const diffInSeconds = Math.floor((now - lastChange) / 1000);

 const intervals = {
   year: 31536000,  // 60 * 60 * 24 * 365
   month: 2592000,  // 60 * 60 * 24 * 30
   week: 604800,    // 60 * 60 * 24 * 7
   day: 86400,      // 60 * 60 * 24
   hour: 3600,      // 60 * 60
   minute: 60,
   second: 1
 };

 for (let unit in intervals) {
   const value = Math.floor(diffInSeconds / intervals[unit]);
   if (value > 0) {
     return `Last changed ${value} ${unit}${value !== 1 ? 's' : ''} ago`;
   }
 }

 return 'Last changed Just now';
}


export const getToken =()=>{
    // return Cookies.get('access_token');
    return localStorage.getItem('access_token');
}



export const getProfileShortName = (name) =>{
    const words = name?.split(' ');
    let initials = '';

       words?.forEach(word => {
        // Take the first character of the word, convert it to uppercase, and add it to initials
        if (word?.length > 0) {
            initials += word[0].toUpperCase();
        }
    });

    return initials?.substring(0,2);
}



export const pageTitle = (title) =>{
    return document.title = title;
}

export const tryCatchError = (error) =>{
    // return toast.error("Somthing Went Wrong!!!");
}

// dashboard local storage
export const setDashboardLocal = (data) =>{
    let userDetails = data.user_detail;
    let userOrgRole = data.user_lo_role;
    let userOrgRoleName = data.user_role_name;
    localStorage.setItem('dashboardData',JSON.stringify({userDetails,userOrgRole,userOrgRoleName}));
    
}

// project localstorage
export const setProjectLocalStorage = (data) =>{
    localStorage.setItem('projectsData',JSON.stringify(data));
}

export const removeProjectDataLocalStoreage = ()=>{
    localStorage.removeItem('projectsData');
}

// userinvitation localstorage
export const setUserInvitationDataLocal = (data) =>{
    localStorage.setItem('userInvitationData',JSON.stringify(data));
}

export const removeUserInviteDataLocalStoreage = ()=>{
        localStorage.removeItem('userInvitationData');
}



// set teamperformance filter 
export const setTeamPerformanceFilterData = (eventuallyReprtsToOptions,reportsToOption,excludeUsersOptions) =>{
    localStorage.setItem('teamperformanceFilterData',JSON.stringify({eventuallyReprtsToOptions,reportsToOption,excludeUsersOptions}));
}

// set teamperformance filter 
export const getTeamPerformanceFilterData = () =>{
    localStorage.getItem('teamperformanceFilterData');
}